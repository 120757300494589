.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 0.25rem !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loginLayout{
  /* background: url("./images/login_bg.png") center center / 100% 100% no-repeat; */
background-color: whitesmoke;
}

.bg {
  background: url("./images/login_bg.png");
  background-size: 100% 100%;
  min-height: 100vh;
}

.app-bar {
  background: #ffffff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.settings-header {
  padding: 50px 0 10px 0;
  background: #fffae6;
}

.common-header {
  /* position: -webkit-sticky;
  position: sticky;
  top: 0; */
  z-index: 4;
}
.common-header .radioCls{
  font-family: Inter;
font-weight: 500;
font-size: 14px;
/* line-height: 20px; */
padding: 0 20px;
color: #B5B5BE;
border-right: 0;
}
.common-header .ant-radio-button-wrapper{
  border-color: #DFE1E6;
  height: 36px;
  line-height: 34px;
}
.common-header .ant-radio-button-wrapper:hover,.common-header{
  border-color: #C1C7D0;
}
.common-header .ant-radio-button-wrapper:first-child{
  border-radius: 3px 0 0 3px;
}
.common-header .ant-radio-button-wrapper:last-child{
  border-radius: 0 3px 3px 0;
  border-right: 1px solid #DFE1E6;
}
.common-header .ant-radio-button-wrapper:not(:first-child)::before{
  background-color: #DFE1E6;
}
.common-header .ant-radio-button-wrapper:not(:first-child):hover::before,
.common-header .ant-radio-button-wrapper:hover + label::before,
.common-header .ant-radio-button-wrapper:last-child:hover::after
{
  background-color: #C1C7D0;

}
.common-header .ant-radio-button-wrapper:last-child::after{
  position: absolute;
    top: -1px;
    right: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #d9d9d9;
    transition: background-color 0.3s;
    content: '';
}
.payments-header {
  padding: 5px 0;
  background: #dfe9f7;
}

.search-header {
  padding: 0;
  background: white;
}

.htl {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
}

.vl {
  left: 50%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
}
.hl {
  top: 50%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 10px;
}
.vl-last {
  left: 50%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 20px;
}
.hl-last {
  top: 98%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 10px;
}

.forgot-password {
  margin-top: 30;
  text-align: right;
}

.logo {
  background: url("./images/forcesight_logo.png");
  width: 204px;
  height: 52px;
  margin: auto;
}

.logo-small {
  background: url("./images/forcesight_logo_small.png");
  width: 139px;
  height: 35px;
  margin: auto;
}

.fs-logo-large {
  background: url("./images/Forcesight_Logo.svg");
  width: 157px;
  height: 35px;
  margin: auto;
  background-size: cover;
}

.review-image {
  background: url("./images/review.png");
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.profit-image {
  background: url("./images/profit.png");
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.orders-image {
  background: url("./images/orders.png");
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.returns-image {
  background: url("./images/returns.png");
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}

.filter-icon {
  background: url("./images/filter.png");
  width: 18px;
  height: 18px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.amazon-logo-small {
  background: url("./images/amazon.png");
  width: 19px;
  height: 16px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.flipkart-logo-small {
  background: url("./images/flipkart.png");
  width: 19px;
  height: 16px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.meesho-logo-small {
  background: url("./images/MeeshoLogo.svg");
  width: 19px;
  height: 16px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.check-icon-small {
  background: url("./images/check.png");
  width: 10px;
  height: 10px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.table-fixed-header {
  position: fixed;
}

.Login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  position: absolute;
  height: 691px;
  width: 511px;
  left: 0px;
  right: -0.5px;
  top: 0px;

  background: #696969;
  border-radius: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Input-field {
  width: "100%";
}

.Title {
  top: 40px;
  left: 40px;
  right: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #283891;
}

.Sub-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 162.02%;
  text-align: center;
  font-feature-settings: "salt" on;
  color: #7a828a;
}

.Input {
  background: #ffffff;
  border: 1px solid rgba(40, 56, 145, 0.05);
  box-sizing: border-box;
  width: 431px;
  margin-top: 9px;
  height: 46px;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
    0px 0px 1px rgba(50, 50, 71, 0.2);
  border-radius: 6px;
}

.Input-label {
  display: inline-block;
  width: 90%;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #7a828a;
}

.Input-hint {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #7a828a;
}

.Forgot-password {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  margin-top: 30px;
  display: flex;
  display: inline-block;
  width: 80%;
  text-align: right;
  color: #283891;
  text-transform: none;
}

.Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #283891;
  border-radius: 4px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.item {
  display: flex;
  justify-content: center;
}

.item-left {
  display: flex;
  justify-content: left;
}

.item .item-image {
  width: 28px;
  height: 28px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in -out;
}

.item .item-title {
  height: 12px;
  width: 50px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.item-left .item-title {
  height: 12px;
  width: 50px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
.primary.MuiButton-root:hover {
  text-decoration: none !important;
  background-color: rgba(38, 132, 255, 0.6) !important;
}
.pincodeNumber input::-webkit-outer-spin-button,
.pincodeNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pincodeNumber input[type=number] {
  -moz-appearance: textfield;
}
.buyboxtag{
  background: #FFF0B3;
border: 1px solid #FFE380;
padding: 2px 6px;
border-radius: 3px;
}
.drawer-container .ant-drawer-content{
  overflow: initial;
}
.settings-tab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,.settings-tab .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,.settings-tab .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,.settings-tab .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab{
  margin-left: 0;
  border-left: 0;
}
.settings-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,.settings-tab .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  background:#fff;
}
.settings-tab .ant-tabs .ant-tabs-tab{
  padding: 12px 20px;
  border-bottom-width: 2px;
  border-bottom-color: transparent;
}
.settings-tab .ant-tabs-nav::before{
  display: none;
}
.settings-tab .ant-tabs-nav-list{
  border-bottom:1px solid #f0f0f0 ;
}
.settings-tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active{
  border-bottom-width: 2px;
}
.settings-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,.settings-tab  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active,.settings-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,.settings-tab .ant-tabs-tab:hover{
  color: #ff8b00;
  border-bottom-color: #ff8b00;
}
.aliceblue{
  background-color: aliceblue;
}