.product-image: {
  width: 48px;
  height: 48px;
}

.customer-name: {
  font-family: "inter";
  font-size: 14px;
  font-weight: 500;
  color: "#44444F";
}

.customer-review-info: {
  font-family: "inter";
  font-size: 14px;
  font-weight: 400;
  color: "#717480";
}

.customer-review: {
  font-family: "inter";
  font-size: 14px;
  font-weight: 400;
  color: "#717480";
}

.star-filled {
  background: url(".././images/star_filled.png");
  width: 22px;
  height: 22px;
  margin: auto;
}

.star-empty {
  background: url(".././images/star_empty.png");
  width: 22px;
  height: 22px;
  margin: auto;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3.375rem;
  left: 3.5%;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn {
  /* border: none;
  outline: none;
  padding: 8px 20px;
  background-color: #fafbfc;
  color: #b5b5be;
  border: none; */
}

/* Style the active class (and buttons on mouse-over) */
/* .active, */
.btn:hover,
.btn:focus {
  background-color: #2684ff;
  color: white;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2684ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/*------ ADDED CSS ---------*/
/* .slider:after {
  content: "OFF";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "ON";
} */

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  opacity: 1;
  display: block;
}

.feedback-filter {
  appearance: none;
  outline: none;
}
.modal{
  z-index: 9999;
}
.modal.fade .modal-dialog{
  transform: none;
}
.disabled-row {
  pointer-events: none;
}