@import url("https://rsms.me/inter/inter.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif !important;
  }
}
body {
  font-family: "Inter var", sans-serif;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
}

code {
  font-family: "Inter var", sans-serif;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
.heading{
  @apply font-Inter text-2xl font-semibold text-ErieBlack;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  @apply hover:border-gray shadow-none;
}
/* .ant-input-affix-wrapper > input.ant-input {
  @apply bg-GhostWhite;
} */
.ant-input-affix-wrapper:hover > input.ant-input {
  @apply bg-white;
}
.ant-input:focus, .ant-input-focused{
  @apply shadow-none border-gray;

}.width-30{
  width: 30%;
}
.width-45{
  width: 45%;
}
.width-48{
  width: 48%;
}
/*Cockpit*/
.padding-36-90{
  padding: 36px 90px;
}
.call-to-action-b-t{
  border-Top: 1px solid rgba(223, 225, 230, 0.5);
}
.small-h{
  color: #44444f;
  font-weight: 500;
}
.Border-radius-3{
  border-radius: 3px;
}
/*Cockpit*/

table {
  width: 100%;
  overflow: scroll;
}
table td.mat-cell, table td.mat-footer-cell, table th.mat-header-cell {
  padding-left: 10px;
  touch-action: pan-x !important;
}
table tr:nth-child(odd) td {
  background: #f8fbff;
}
table tr:nth-child(even) td {
  background: #fff;
}
table tr:hover td {
  background: #f3f3f3;
}
.font-32{
  font-size: 32px;
}
.height-fit{
  height: fit-content;
}
.bk-nav{
  background-color: white;
  box-shadow: rgb(23 43 77 / 4%) 0px 0px 2px, rgb(23 43 77 / 8%) 0px 3px 2px;
}
.bk-white{
  background-color: white !important;
}
.ant-form-item {
  margin-bottom: 0%;
}
/* td.ant-table-cell{
max-width: 100px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
} */