/* .button {
  display: none;
  font-Family: Inter !important;
  font-Size: 14px !important;
  line-Height: 22px !important;
  font-Weight: 500 !important;
  color: #ffffff !important;
  background-Color: #4ECD98 !important;
  cursor: pointer;
} */

.button {
  cursor: pointer;
  display: inline-block;
  font-Family: Inter;
  font-Size: 14px;
  line-Height: 22px;
  font-Weight: 500;
  color: #ffffff !important;
  background-Color: #4ECD98;
  padding: 8px 20px;
  margin-left: 20px;
  border-radius: 5px;
}

/* plan table */
.plan_table table {
  border-collapse: collapse;
  color: #707070;
}

.plan_table th,
.plan_table td {
  text-align: right;
  height: 40px;
}

.plan_table th {
  background-color: #fffae6;
  width: 200px;
}

.plan_table table,
.plan_table tr {
  border: 1px solid #DFE1E6;
}

/* Table Hover */
.plan_table table {
  border-spacing: 0;
  border-collapse: collapse;
  overflow: hidden;
  z-index: 1;
}

.plan_table td,
.plan_table th,
.plan_table .ff-fix {
  cursor: pointer;
  position: relative;
}

.plan_table td:hover::after,
.plan_table .ff-fix:hover::after {
  background-color: #fffae6;
  content: '\00a0';
  height: 10000px;
  left: 0;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
}

.plan_table {
  overflow: hidden;
}

/* invoice table*/
.invoice_table table {
  border-collapse: collapse;
  color: #707070;
}

.invoice_table th,
.invoice_table td {
  text-align: left;
  padding: 8px;
}

.invoice_table th {
  background-color: #fffae6;
}

.invoice_table table,
.invoice_table tr {
  border: 1px solid #DFE1E6;
}

.plan_table tr:last-child {
  font-weight: bold;
}

.plan_table th,
.plan_table td {
  text-align: right;
  height: 40px;
}

.plan_table td:first-child {
  text-align: left;

}

.notification-success {
  background-color: #ddffdd;
}
.notification-error {
  background-color: #f44336;
}
.notification-loading {
  background-color: #2f96b4;
}
.notification-warning {
  background-color: #ffffcc;
}

.ant-modal-footer{
  padding: 10px 24px;
}