.header-table-layout .ant-table-thead {
  height: 55px;
  border-bottom: 1px solid #E0E0E0;
}

.header-table-layout .ant-table-thead .ant-table-cell {
  color: #44444F;
  font-size: 10px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  height: 55px;
}

.header-table-layout .ant-table-row {
  height: 63px;
  border-bottom: 1px solid #F2F2F2;
}
.header-table-layout .ant-table-row .ant-table-cell {
  color: #000000;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  border: 1px solid #9a9c9c;
}

.header-table-layout .ant-table-row .ant-table-cell .ant-form-item-control-input-content input{
  height: 63px;
  width: 100%;
  border: 1px solid #DFE1E6;
  text-align: center;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
}
.ant-table-column-sorter.ant-table-column-sorter-full {
  color: #424040;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
  color:  #424040;
}
.header-table-layout .ant-table-row .ant-table-cell .ant-form-item-control-input-content input.heightInitial{
  height: initial;
}
.header-table-layout .ant-table{
  margin-bottom: 60px;
}
.header-table-layout{
  position: relative;
  border-right: 1px solid #9a9c9c;
}
/* .header-table-layout .ant-table-summary{
  bottom: 56px !important;
} */
.header-table-layout .ant-table-thead .ant-table-cell, .header-table-layout .ant-table-row .ant-table-cell, .header-table-layout .ant-tag,.header-table-layout td, .header-table-layout p,.header-table-layout .ant-pagination, .header-table-layout .ant-select,.header-table-layout .ant-select .ant-select-item{
  font-size: 12px;
}
.ant-table-thead > tr >th{
  /* color: white; */
  background: #81aee1 !important;
  border: 1px solid #9a9c9c;
  height: 5px;
  padding: 4px;
}
.header-table-layout .ant-pagination{
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
  background-color: #fff;
  z-index: 4;
  margin: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  /* top:216px !important; */
}
.header-table-layout .ant-table-header,.header-table-layout .ant-table-header thead th,.header-table-layout .ant-table-thead th.ant-table-column-has-sorters:hover{
  background-color: #f5f5f5;
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
  background: transparent;
}
.header-table-layout .ant-table-column-title,.header-table-layout .ant-table-thead .ant-table-cell {
  font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


/* tableTitle */

color: #222228;
}
.header-table-layout .ant-table-summary{
  background: #F4F5F7;
border: 1px solid  #9a9c9c;
}
.header-table-layout .ant-table-expanded-row-fixed,.header-table-layout .ant-table-expanded-row{
  background: rgba(222, 235, 255, 0.1);
}
.header-table-layout .ant-table-summary .ant-table-cell-fix-left,.header-table-layout .ant-table-summary  .ant-table-cell-fix-right{
  background: #102f6d;

}
.isTableLoading .ant-table-header{
  cursor: not-allowed;
}
.isTableLoading .ant-table-header table{
  pointer-events: none;
}
/* .isTableLoading tbody .ant-table-cell{
  width: 100%;
    height: 16px;
    margin-top: 16px;
    border-radius: 4px;
  background: linear-gradient(
    90deg
    , rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
        background-size: 400% 100%;
        -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
        animation: ant-skeleton-loading 1.4s ease infinite;
} */

