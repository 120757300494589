@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
.setting-tab {
  padding: 12px 20px;
  background-color: #ffffff;
  border-right:1px solid rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px -1px 0px rgba(223, 225, 230, 0.25);
  border-bottom: 2px solid #ffffff;
  cursor: pointer;
  color: #696974;
  font-family: Inter;
font-size: 14px;
line-height: 20px;
}

.setting-tab-active {
  cursor: pointer;
  padding: 12px 20px;
  color: #ff8b00;
  background-color: #ffffff;
  border-right:1px solid rgba(0, 0, 0, 0.05);

  border-bottom: 2px solid #ff8b00;
  font-family: Inter;
font-weight: 500;
font-size: 14px;
line-height: 20px;
}


.modal-backdrop1 {
  background-color: rgba(255, 255, 255, 0);
}

.main_onboard_modal .modal-content {
  width: 190px;
  margin-left: 80%;
  margin-top: 140%;
}

.adduser_modal .modal-content {
  width: 500px;
}
.deluser_modal .modal-content {
  width: 500px;
  position: absolute;
}
.inter {
  font-family: "Inter var";
}
